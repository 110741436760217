<template>
    <div id="AdminSystemDashboard" class="serif dashboard-style">
        <aside>
            <router-link :to="{ name: 'Home' }">
                <img :src="require('@/assets/logo/vertical-logo.png')" />
            </router-link>
            <ul class="f16 bold">
                <li>
                    <router-link :to="{ name: 'AdminMemberReview' }"
                        ><ElIcon><Clock /></ElIcon>界員認証</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'MemberOverview' }"
                        ><ElIcon><User /></ElIcon>界員</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'AdminCourseList' }"
                        ><ElIcon><Notebook /></ElIcon>真理学修</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'AdminDonateOverview' }"
                        ><ElIcon><Money /></ElIcon>おつなぎ徳積み</router-link
                    >
                </li>
                <li>
                    <a
                        href="https://id-sso.reserva.be/login/business"
                        target="_blank"
                        ><ElIcon><Calendar /></ElIcon>予約管理<el-text
                            class="f14 f12-mb"
                        >
                            ※別サイト</el-text
                        >
                    </a>
                </li>

                <li>
                    <router-link :to="{ name: 'AdminChangePassword' }"
                        ><ElIcon><Lock /></ElIcon>パスワードの変更</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'Home' }" @click="logout">
                        <ElIcon><Back /></ElIcon>ログアウト
                    </router-link>
                </li>
            </ul>
        </aside>
        <main class="f20">
            <ElBreadcrumb separator="/">
                <ElBreadcrumb-item
                    v-for="item in route.meta.breadcrumb"
                    :key="item.title"
                >
                    <router-link v-if="item.routeUrl" :to="item.routeUrl">
                        {{ item.title }}
                    </router-link>
                    <span v-else>{{ item.title }}</span>
                </ElBreadcrumb-item>
                <ElBreadcrumb-item v-if="route.params.subCategory"
                    >{{ route.params.subCategory }}
                </ElBreadcrumb-item>
                <ElBreadcrumb-item v-if="route.params.userId"
                    >{{ route.params.userId }}
                </ElBreadcrumb-item>
            </ElBreadcrumb>
            <router-view class="container"></router-view>
        </main>
    </div>
</template>
<script>
export default {
    name: 'AdminSystemDashboard',
}
</script>
<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
    Clock,
    User,
    Notebook,
    Money,
    Lock,
    Back,
    Calendar,
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

const store = useStore()
const route = useRoute()
const router = useRouter()
onMounted(async () => {
    // do something
})
function logout() {
    store.commit('logout')
    ElMessage.success('ログアウトに成功しました。')
    router.push({ name: 'AdminSystemLogin' })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
